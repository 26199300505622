const alunosTurma = [

    //TURMA 1
    {
        turma: "Turma 1",
        info: [
            {
                name: "Bruno R. Rodrigues",
                img_profile: 'assets/img/turma1/bruno.png',
                linkGit: '#'
            },
            {
                name: "Eduardo A. Araújo",
                img_profile: 'assets/img/turma1/eduardo.png',
                linkGit: '#'
            },
            {
                name: "Gabriel B. Cerquinho",
                img_profile: 'assets/img/turma1/gabriel.png',
                linkGit: '#'
            },
            {
                name: "Gleides Silva",
                img_profile: 'assets/img/turma1/gleides.png',
                linkGit: '#'
            },
            {
                name: "Gutemberg B. Ferreira",
                img_profile: 'assets/img/turma1/gutemberg.jpeg',
                linkGit: 'https://www.linkedin.com/in/gutembergbritoferreira/'
            },
            {
                name: "Jeison P. Oliveira",
                img_profile: 'assets/img/turma1/jeison.png',
                linkGit: '#'
            },
            {
                name: "Jonathas B. Cavalcante",
                img_profile: 'assets/img/turma1/jonathas.png',
                linkGit: 'https://www.linkedin.com/in/jonathascavalcante'
            },
            {
                name: "José Alberto Filho",
                img_profile: 'assets/img/turma1/jose.png',
                linkGit: 'https://www.linkedin.com/in/jose-alberto'
            },
            {
                name: "José Rodrigues",
                img_profile: 'assets/img/turma1/mateus.png',
                linkGit: 'https://www.linkedin.com/in/jose-mateus-cordova-rodrigues/'
            },
            {
                name: "Josias B. F. Cavalcante",
                img_profile: 'assets/img/turma1/josias.png',
                linkGit: 'https://www.linkedin.com/in/josiascavalcante/ '
            },
            {
                name: "Josué Lustosa Filho",
                img_profile: 'assets/img/turma1/josue.png',
                linkGit: 'https://www.linkedin.com/in/josue-lustosa/'
            },
            {
                name: "Laura Lima Guedes",
                img_profile: 'assets/img/turma1/laura.png',
                linkGit: '#'
            },
            {
                name: "Nathália R. M. Santos",
                img_profile: 'assets/img/turma1/nathalia.png',
                linkGit: 'https://www.linkedin.com/in/nathalia-santos-ns27/'
            },
            {
                name: "Paula Mendonça",
                img_profile: 'assets/img/turma1/paula.png',
                linkGit: 'https://www.linkedin.com/in/paula-quadros'
            },
            {
                name: "Shermam Lima",
                img_profile: 'assets/img/turma1/shermam.png',
                linkGit: '#'
            },
            {
                name: "Solano Lima Oliveira",
                img_profile: 'assets/img/turma1/solano.png',
                linkGit: 'https://www.linkedin.com/in/solano-lima-oliveira-146b23188/'
            },
            {
                name: "Taynara Costa",
                img_profile: 'assets/img/turma1/taynara.png',
                linkGit: 'https://www.linkedin.com/in/taynara-costa-944798198/'
            },
        ],


    },

    //TURMA 2
    {
        turma: "Turma 2",
        info: [
            {
                name: "Adriano  Gomes",
                img_profile: 'assets/img/turma2/adriano.jpeg',
                linkGit: 'https://www.linkedin.com/in/adriano-gomes-9b1779186/'
            },
            {
                name: "Antônio José Neto",
                img_profile: 'https://ca.slack-edge.com/T052KR1029Y-U05MBSATXMM-872a35a66d02-512',
                linkGit: 'https://www.linkedin.com/in/antonio-fernandes-19287325/'
            },

            {
                name: "Aris Canto",
                img_profile: 'https://ca.slack-edge.com/T052KR1029Y-U05MKP8S30E-26a8d4f035bb-512',
                linkGit: '#'
            },
            {
                name: "Caio Fernandes",
                img_profile: 'https://ca.slack-edge.com/T052KR1029Y-U05NG3ZSDPS-4c5769619cee-512',
                linkGit: 'https://www.linkedin.com/in/caio-fernandes-965196209/'
            },
            {
                name: "Caroline Braz",
                img_profile: 'assets/img/turma2/carol.jpg',
                linkGit: 'https://www.linkedin.com/in/caroline-braz-43961b146/'
            },
            {
                name: "Crislan Rodrigues Cruz",
                img_profile: 'assets/img/turma2/crislan.jpg',
                linkGit: 'https://www.linkedin.com/in/crislancruz'
            },
            {
                name: "Daniel Antunes Ribeiro",
                img_profile: 'https://ca.slack-edge.com/T052KR1029Y-U05MPF7SL6R-824484f7c933-512',
                linkGit: 'https://www.linkedin.com/in/daniel-antunes-ribeiro'
            },
            {
                name: "Eduardo Silva",
                img_profile: 'assets/img/turma2/eduardo.jpg',
                linkGit: '#'
            },
            {
                name: "Eduardo Noji",
                img_profile: 'https://ca.slack-edge.com/T052KR1029Y-U05MS9WJS67-62a81a5b510a-512',
                linkGit: 'https://www.linkedin.com/in/eduardonoji/'
            },
            {
                name: "Francisco Gabriel",
                img_profile: 'https://ca.slack-edge.com/T052KR1029Y-U05MSCQ5BH8-e0218ae3bca9-512',
                linkGit: 'https://www.linkedin.com/in/francisco-gabriel-software/'
            },
            {
                name: "Gabriel Sobrinho",
                img_profile: 'https://ca.slack-edge.com/T052KR1029Y-U05N51YGTMX-75f78d6bb02d-512',
                linkGit: '#'
            },
            {
                name: "Gabriel Amoêdo",
                img_profile: 'https://ca.slack-edge.com/T052KR1029Y-U05346W0E2D-c7b106c98540-512',
                linkGit: 'https://www.linkedin.com/in/gabriel-amo%C3%AAdo-a1b078a7'
            },
            {
                name: "Ícaro Santos",
                img_profile: 'https://avatars.githubusercontent.com/u/46682140?v=4',
                linkGit: 'https://www.linkedin.com/in/icarosun'
            },
            {
                name: "Luiz Gustavo Arruda",
                img_profile: 'assets/img/turma2/luiz.jpg',
                linkGit: 'https://www.linkedin.com/in/luiz-gustavo-arruda-15703194'
            },
            {
                name: "Noah Diunkz",
                img_profile: 'https://avatars.githubusercontent.com/u/18603573?v=4',
                linkGit: 'https://br.linkedin.com/in/diunkz'
            },
            {
                name: "Salomão Cruz",
                img_profile: 'assets/img/turma2/salomao.jpg',
                linkGit: 'https://www.linkedin.com/in/salomao-cruz/'
            },
            {
                name: "Sidney Araújo",
                img_profile: 'https://ca.slack-edge.com/T052KR1029Y-U05MPFVHSE9-ff36e601d52e-512',
                linkGit: 'https://www.linkedin.com/in/sidney-de-araujo-fonseca-20414493'
            },
            {
                name: "Vitor Xavier",
                img_profile: 'assets/img/turma2/vitor.jpg',
                linkGit: '#'
            },
            {
                name: "Vitória Beatriz",
                img_profile: 'https://avatars.githubusercontent.com/u/91225063?v=4',
                linkGit: 'https://www.linkedin.com/in/vit%C3%B3ria-beatriz-324481224'
            },

        ]
    },

    //TURMA 3
    {
        turma: "Turma 3",
        info: [
            {
                name: 'Allan Aguiar',
                img_profile: 'assets/img/turma3/allan.png',
                linkGit: 'https://www.linkedin.com/in/allan-carvalho-687056192'
            },
            {
                name: 'Ayrton Lemes',
                img_profile: 'assets/img/turma3/ayrton.png',
                linkGit: 'http://www.linkedin.com/in/ayrton-finicelli-lemes-9b4481225'
            },

            {
                name: 'Brenda Moura',
                img_profile: 'assets/img/turma3/brenda.jpeg',
                linkGit: 'https://www.linkedin.com/in/brenda-cd-moura/'
            },
            {
                name: 'Cáio Gonzaga',
                img_profile: 'assets/img/turma3/caio.png',
                linkGit: 'http://linkedin.com/in/caio-cesar-b66910242'
            },
            {
                name: 'Daniele Greice',
                img_profile: 'assets/img/turma3/daniele.jpeg',
                linkGit: 'https://www.linkedin.com/in/daniele-greice-00b484186/'
            },
            {
                name: 'Davi Souza',
                img_profile: 'assets/img/turma3/davi.jpeg',
                linkGit: 'https://www.linkedin.com/in/davi-souza-5057901b3/'
            },
            {
                name: 'Gabriel Freitas',
                img_profile: 'assets/img/turma3/gabrielF.png',
                linkGit: 'http://www.linkedin.com/in/gabriel-freitas-507baa274'
            },
            {
                name: 'Gabriel Pacheco',
                img_profile: 'assets/img/turma3/gabrielP.png',
                linkGit: 'https://www.linkedin.com/in/gabrielspc/'
            },
            {
                name: 'Girlana Santos',
                img_profile: 'assets/img/turma3/girlana.png',
                linkGit: 'https://www.linkedin.com/in/girlana-souza-041200220/'
            },
            {
                name: 'Isabella Mendes',
                img_profile: 'assets/img/turma3/isabella.png',
                linkGit: 'https://www.linkedin.com/in/isabella-negr%C3%A3o-7b3899133/'
            },
            {
                name: 'Max Souza',
                img_profile: 'assets/img/turma3/max.jpeg',
                linkGit: 'http://www.linkedin.com/in/max-souza-4533b6196'
            },
            {
                name: 'Natanael de Oliveira',
                img_profile: 'assets/img/turma3/natanael.jpeg',
                linkGit: 'https://www.linkedin.com/in/natanael-bezerra/'
            },
            {
                name: 'Rodrigo Santos',
                img_profile: 'assets/img/turma3/rodrigo.jpeg',
                linkGit: 'https://www.linkedin.com/in/rodrigosc/'
            },
            {
                name: 'Saile Costa',
                img_profile: 'assets/img/turma3/saile2.png',
                linkGit: 'https://www.linkedin.com/in/saile-santos/'
            },
            {
                name: 'Thiago Oliveira',
                img_profile: 'assets/img/turma3/tiago.png',
                linkGit: 'https://www.linkedin.com/in/thiago-de-oliveira-899664b0'
            },
            {
                name: 'Vinicius Fonseca',
                img_profile: 'assets/img/turma3/vinicius.png',
                linkGit: 'https://www.linkedin.com/in/vinicius-luiz-nunes-da-fonseca-250aa9272/'
            },

        ]
    },

    //TURMA 4
    {
        turma: "Turma 4",
        info: [
            {
                name: 'Adriano Nobre',
                img_profile: 'assets/img/turma4/adriano.jpeg',
                linkGit: 'https://www.linkedin.com/in/adrianobre/'
            },
            {
                name: 'Beatriz Aguiar',
                img_profile: 'assets/img/turma4/beatriz.jpeg',
                linkGit: 'https://www.linkedin.com/in/beatriz-aguiar-821b40241/'
            },
            {
                name: 'Bruno Solimões',
                img_profile: 'assets/img/turma4/bruno.jpeg',
                linkGit: 'https://www.linkedin.com/in/bruno-de-moura-solim%C3%B5es-455523177/'
            },
            {
                name: 'Charlene Queiroz',
                img_profile: 'assets/img/turma4/charlene.jpeg',
                linkGit: ''
            },
            {
                name: 'Cristian Garcia',
                img_profile: 'assets/img/turma4/cristian.png',
                linkGit: 'https://www.linkedin.com/in/cristian-garcia-web/'
            },
            {
                name: 'Daniel Leal',
                img_profile: 'assets/img/turma4/daniel.jpeg',
                linkGit: 'https://www.linkedin.com/in/dan-leal/'
            },
            {
                name: 'Ecthon Almeida',
                img_profile: 'assets/img/turma4/ecthon.jpeg',
                linkGit: 'https://www.linkedin.com/in/ecthon/'
            },
            {
                name: 'Eduardo Okita',
                img_profile: 'assets/img/turma4/okita.png',
                linkGit: 'https://www.linkedin.com/in/eduardo-okita/'
            },
            {
                name: 'Erik Lima',
                img_profile: 'assets/img/turma4/erik.jpeg',
                linkGit: 'https://www.linkedin.com/in/erik-gustavo-oliveira-71034a204/'
            },
            {
                name: 'Fílip Anselmo',
                img_profile: 'assets/img/turma4/filip.jpeg',
                linkGit: 'https://www.linkedin.com/in/filip-anselmo-04b651152/'
            },
            {
                name: 'Francisco Júnior',
                img_profile: 'assets/img/turma4/francisco.jpeg',
                linkGit: 'https://www.linkedin.com/in/francisco-rivail-8703961a0/'
            },
            {
                name: 'Franklin Rosevelt',
                img_profile: 'assets/img/turma4/franklin.jpeg',
                linkGit: 'https://www.linkedin.com/in/franklin-roosevelt-xavier-dantas-180804311/'
            },
            {
                name: 'Ítalo Pinheiro',
                img_profile: 'assets/img/turma4/italo.png',
                linkGit: 'https://www.linkedin.com/in/italo-feitosa-pinheiro/'
            },
            {
                name: 'José Lázaro',
                img_profile: 'assets/img/turma4/lazaro.jpeg',
                linkGit: 'https://www.linkedin.com/in/jos%C3%A9-l%C3%A1zaro-marques-28075030a/'
            },
            {
                name: 'Kristine Santana',
                img_profile: 'assets/img/turma4/kristine.jpeg',
                linkGit: 'https://www.linkedin.com/in/kristine-santana/'
            },
            {
                name: 'Marcos Rebelo',
                img_profile: 'assets/img/turma4/marcos.jpeg',
                linkGit: 'https://www.linkedin.com/in/marcos-guerreiro-rebelo/'
            },
            {
                name: 'Matheus Oliveira',
                img_profile: 'assets/img/turma4/matheus.jpeg',
                linkGit: 'https://www.linkedin.com/in/matheusouzaoliveira/'
            },
            {
                name: 'Rafael Carvalho',
                img_profile: 'assets/img/turma4/raphael.jpeg',
                linkGit: '#'
            },
            {
                name: 'Rodrigo Queiroz',
                img_profile: 'assets/img/turma4/rodrigo.jpeg',
                linkGit: 'https://www.linkedin.com/in/rodnobr/'
            },
            {
                name: 'Suelen Pereira',
                img_profile: 'assets/img/turma4/suellen.jpeg',
                linkGit: '#'
            },
            {
                name: 'Thiago Martins',
                img_profile: 'assets/img/turma4/thiago.png',
                linkGit: 'https://www.linkedin.com/in/thiagomartins05/'
            },

        ]
    }
]

export default alunosTurma;