import React from "react";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table"


function Curso() {
	return (
		<Container className="mt-5">
			<div className="row g-3 justify-content-center mb-5">
				<div className="col-md-8 mt-3">
					<h2>Sobre o Curso de Capacitação</h2>

					<p style={{ fontSize: '1.1em', textAlign: 'justify' }}>
						O curso tem como objetivo principal formar recursos humanos com habilidades para
						conceber, projetar, implementar e testar aplicações de software voltadas para a
						Internet, utilizando metodologias e ferramentas modernas, capazes de atender as
						demandas emergentes do mercado de desenvolvimento de software.
					</p>


				</div>

			</div>

			<div className="row g-3 justify-content-center mb-5">
				<div className="col-md-8 mt-3">
					<h2>Informações Gerais</h2>

					<Table responsive>
						<tbody style={{ fontSize: '1.1em' }}>
							<tr>
								<th>Duração do Curso </th>
								<td>8 meses</td>
							</tr>
							<tr>
								<th>Carga Horária</th>
								<td>348 horas (240 horas de aulas de conteúdo teórico e prático; 108 horas de práticas hands-on )</td>
							</tr>
							<tr>
								<th style={{ width: '230px' }}>Horário das Aulas</th>
								<td> Terças e quintas, das 18h15 às 21h15. E sábado, das 8h15 às 11h15</td>
							</tr>
							<tr>
								<th>Início das Aulas Turma 5 (previsto)</th>
								<td>01/04/2025</td>
							</tr>
						</tbody>
					</Table>


				</div>
			</div>

			<div className="row g-3 justify-content-center mb-5">
				<div className="col-md-8 mt-3">
					<h2>Auxílio Financeiro</h2>

					<p style={{ fontSize: '1.1em', textAlign: 'justify' }}>
						Visando incentivar a matrícula e permanência no curso, o projeto prevê o pagamento
						de auxílio financeiro para alunos selecionados. Serão disponibilizados 18 auxílios financeiros.
						Cada auxílio será de R$ 900,00 (novecentos reais) por mês, no período de 8 meses.
					</p>


				</div>
			</div>

			<div className="row g-3 justify-content-center">
				<div className="col-md-8 mt-3">
					<h2>Ementa</h2>

					<Table responsive="sm">
						<tbody style={{ fontSize: '1.1em' }}>
							<tr>
								<th>Disciplina</th>
								<th>Tecnologias e Ferramentas</th>
								<th>CH</th>
							</tr>
							<tr>
								<td>Controle de Versão</td>
								<td>Git, GitHub</td>
								<td>8</td>
							</tr>
							<tr>
								<td>Fundamentos de HTML</td>
								<td>HTML 5</td>
								<td>8</td>
							</tr>
							<tr>
								<td>Fundamentos de CSS</td>
								<td>CSS 3</td>
								<td>12</td>
							</tr>
							<tr>
								<td>Fundamentos de Javascript</td>
								<td>Javascript</td>
								<td>12</td>
							</tr>
							<tr style={{ verticalAlign: "middle" }}>
								<td>Introdução ao DOM (Document Object Model)</td>
								<td>Javascript</td>
								<td>8</td>
							</tr>
							<tr>
								<td>Introdução ao Framework Bootstrap</td>
								<td>Javascript, Bootstrap</td>
								<td>8</td>
							</tr>
							<tr>
								<td>Fundamentos de NodeJS</td>
								<td>NodeJS, NPM, Javascript</td>
								<td>8</td>
							</tr>
							<tr>
								<td>Fundamentos de Typescript</td>
								<td>Typescript, NodeJS</td>
								<td>12</td>
							</tr>
							<tr>
								<td>Básico Express</td>
								<td>Framework Express</td>
								<td>16</td>
							</tr>
							<tr style={{ verticalAlign: "middle" }}>
								<td>Nivelamento de Banco de Dados</td>
								<td>Diagrama ER/EER e OO, Normalização, Desnormalização, Integração NoSQL</td>
								<td>12</td>
							</tr>
							<tr>
								<td>Fundamentos de Banco de Dados</td>
								<td>MySQL, Prisma, Models, Migrations, Seeders</td>
								<td>12</td>
							</tr>
							<tr>
								<td>Cookies, Sessões e Autenticação</td>
								<td>Cookies, Sessions, Bcrypt</td>
								<td>8</td>
							</tr>
							<tr>
								<td>Contêineres </td>
								<td>Docker & Docker Compose</td>
								<td>8</td>
							</tr>
							<tr>
								<td>Desenvolvimento de RESTful APIs </td>
								<td>Framework Express</td>
								<td>12</td>
							</tr>
							<tr>
								<td>Biblioteca Frontend REACT</td>
								<td>React</td>
								<td>16</td>
							</tr>
							<tr>
								<td>Gerenciamento de Estado </td>
								<td>Redux, Context API</td>
								<td>8</td>
							</tr>
							<tr>
								<td>Fundamentos de Teste de Software</td>
								<td>Jest</td>
								<td>8</td>
							</tr>
							<tr style={{ verticalAlign: "middle" }} >
								<td>Fundamentos de Integração Contínua e Deploy de Aplicação Web</td>
								<td style={{ verticalAlign: "middle" }}>GitHub Actions</td>
								<td>16</td>
							</tr>
							<tr>
								<td>Processo de Desenvolvimento Ágil</td>
								<td>Jira<sup>TM</sup> Software, GitHub Projects </td>
								<td>12</td>
							</tr>
							<tr>
								<td>Ciência de Dados</td>
								<td>Python, Google Colab, StreamLit</td>
								<td>12</td>
							</tr>
							<tr>
								<td>Aprendizado de Máquina</td>
								<td>Python, Google Colab</td>
								<td>12</td>
							</tr>
							<tr>
								<td>Cibersegurança Web</td>
								<td></td>
								<td>12</td>
							</tr>
							<tr>
								<td>Desenvolvimento de Projeto Prático Guiado (hands-on)</td>
								<td></td>
								<td>108</td>
							</tr>
						</tbody>
					</Table>


				</div>
			</div>
		</Container>
	)
}

export default Curso