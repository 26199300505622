import React, { useState } from "react";
import { Button, Container } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxArchive, faSquareCheck, faListCheck, faFilter } from "@fortawesome/free-solid-svg-icons";

import Card from '../../components/card/Card';
import CardView from "../../components/cardview/CardView";
import HandsonData from "./HandSonData";

function Portifolio() {

    const handsonTurma = HandsonData.flatMap((turma) => turma.projects); // Usamos flatMap para transformar o array de arrays em um único array de projetos

    // Estado para armazenar o filtro selecionado
    const [filter, setFilter] = useState(null);

    // Função para aplicar o filtro
    const applyFilter = (status) => {
        setFilter(status);
    };

    // Filtra os projetos com base no filtro selecionado
    const filteredProjects = filter ? handsonTurma.filter((projeto) => projeto.status === filter) : handsonTurma;

    return (
        <Container>
            <div className="row g-3 justify-content-center mb-5">
                <div className="col-md-10 mt-3">
                    <h3 style={{ marginTop: '50px' }}>Portfólio</h3>
                    <p>Conheça os produtos desenvolvidos na disciplina de Hands-on</p>
                    <div className="roww">
                        <Card
                            qtd={handsonTurma.length}
                            descricao={'Total de Projetos'}
                            icon={faBoxArchive}
                        />
                        <Card
                            qtd={handsonTurma.filter((projetos) => projetos.status === "Finalizado").length}
                            descricao={'Projeto(s) Finalizados'}
                            icon={faSquareCheck}
                        />
                        <Card
                            qtd={handsonTurma.filter((projetos) => projetos.status === "Em andamento").length}
                            descricao={'Projeto(s) Em Andamento'}
                            icon={faListCheck}
                        // onClick={() => applyFilter('Em andamento')}
                        />
                    </div>
                    <div style={{ textAlign: 'center', marginBottom: '18px' }}>
                        <Button
                            className={filter === null ? 'active' : ''}
                            onClick={() => applyFilter(null)}>
                            <FontAwesomeIcon icon={faFilter} /> {' '}
                            Todos
                        </Button>{' '}
                        <Button
                            className={filter === 'Finalizado' ? 'active' : ''}
                            onClick={() => applyFilter('Finalizado')}>
                            <FontAwesomeIcon icon={faFilter} /> {' '}
                            Finalizados
                        </Button>{' '}
                        <Button
                            className={filter === 'Em andamento' ? 'active' : ''}
                            onClick={() => applyFilter('Em andamento')}>
                            <FontAwesomeIcon icon={faFilter} /> {' '}
                            Em Andamento
                        </Button>
                    </div>
                    <div className="father card-custom">
                        {filteredProjects.map((projeto) => (
                            <CardView
                                key={projeto.id}
                                title={projeto.title}
                                description={projeto.description}
                                developers={projeto.developers}
                                status={projeto.status}
                                logotipo={projeto.logo}
                                gitgithub={projeto.github}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default Portifolio;
