import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import CardDocente from "../../components/cardDocente/CardDocente";
import DocentesData from '../../components/cardDocente/DocentesData';

import './Docentes.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

function Docentes() {
    const [query, setQuery] = useState('');
    const [filteredDocentes, setFilteredDocentes] = useState(DocentesData);

    const handleSearch = (e) => {
        const searchQuery = e.target.value.toLowerCase();
        setQuery(searchQuery);
        const results = DocentesData.filter(d =>
            d.nome.toLowerCase().includes(searchQuery) ||
            d.titulacao.toLowerCase().includes(searchQuery) ||
            d.bio.toLowerCase().includes(searchQuery)
        );
        setFilteredDocentes(results);
    };

    const docentes = filteredDocentes.map((d, index) => {
        return (
            <CardDocente
                key={d.id}
                nome={d.nome}
                image={d.image}
                titulacao={d.titulacao}
                bio={d.bio}
                linkLinkedin={d.linkLinkedin}
                linkGit={d.linkGit}
            />
        );
    });

    return (
        <Container>
            <div className="row g-3 justify-content-center mb-5">
                <div className="col-md-10 mt-5">
                    <h2>Docentes</h2>
                    <p style={{ fontSize: '1.1em', textAlign: 'justify' }}>
                        O Projeto WebAcademy conta com a colaboração de Professores(as) com anos de experiência acadêmica e na indústria da tecnologia.
                    </p>
                    <div className="search_input">
                        <div className="p-1 bg-light rounded rounded-pill shadow-sm mb-4 ">
                            <div className="input-group">
                                <input
                                    type="search"
                                    value={query}
                                    onChange={handleSearch}
                                    placeholder="Buscar docente..."
                                    className="form-control border-0 bg-light " />
                                <div className="input-group-append">
                                    <button
                                        id="button-addon1"
                                        type="submit"
                                        className="btn btn-link text-primary">
                                        <FontAwesomeIcon icon={faSearch} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", flexWrap: "wrap", alignContent: "space-around" }}>
                        {docentes}
                    </div>
                </div>
            </div>
        </Container >
    );
}

export default Docentes;
