const HandsonData = [
    {
        id: '4',
        turma: 'Turma 4',
        projects: [
            {
                id: '13',
                title: 'Wikinook',
                description: 'Plataforma colaborativa desenvolvida para facilitar que usuários criem e editem artigos com texto e multimídia em tempo real.',
                categories: 'Literatura, Entreitenimento',
                logo: 'assets/img/handson/wikinook.png',
                status: 'Finalizado',
                github: 'https://github.com/webacademyufam/hands-on-t4-01-wikinook',
                developers: [
                    {
                        name: 'Bruno Solimões',
                        img_profile: 'assets/img/turma4/bruno.jpeg',
                    },
                    {
                        name: 'Ecthon Almeida',
                        img_profile: 'assets/img/turma4/ecthon.jpeg',
                    },
                    {
                        name: 'Erik Oliveira',
                        img_profile: 'assets/img/turma4/erik.jpeg',
                    },
                    // {
                    //     name: 'Millena Sales',
                    //     img_profile: 'assets/img/turma4/millena.jpeg',
                    // },
                    {
                        name: 'Rafael Castilho',
                        img_profile: 'assets/img/turma4/raphael.jpeg',
                    },
                    {
                        name: 'Rodrigo Nobre',
                        img_profile: 'assets/img/turma4/rodrigo.jpeg',
                    },
                ]
            },
            {
                id: '14',
                title: 'Archiwise',
                description: 'O sistema automatiza por meio da I.A. a catalogação e indexação, otimizando a busca por arquivos e agilizando o acesso às informações.',
                categories: 'Gestão, Documentos',
                logo: 'assets/img/handson/archiwise.png',
                status: 'Finalizado',
                github: 'https://github.com/webacademyufam/hands-on-t4-02-archiwise',
                developers: [
                    {
                        name: 'Francisco Rivail',
                        img_profile: 'assets/img/turma4/francisco.jpeg',
                    },
                    {
                        name: 'Franklin Dantas',
                        img_profile: 'assets/img/turma4/franklin.jpeg',
                    },
                    {
                        name: 'Italo Pinheiro',
                        img_profile: 'assets/img/turma4/italo.png',
                    },
                    // {
                    //     name: 'Janaina Ferreira',
                    //     img_profile: 'assets/img/turma4/janaina.jpeg',
                    // },
                    {
                        name: 'Marcos  Guerreiro',
                        img_profile: 'assets/img/turma4/marcos.jpeg',
                    },
                    {
                        name: 'Matheus Oliveira',
                        img_profile: 'assets/img/turma4/matheus.jpeg',
                    },
                ]
            },
            {
                id: '15',
                title: 'Glowmatch',
                description: 'E-commerce de maquiagens que permite aos usuários fazerem upload de uma foto para receber recomendações personalizadas de produtos por meio de I.A..',
                categories: 'Social',
                logo: 'assets/img/handson/glowmatch.png',
                status: 'Finalizado',
                github: 'https://github.com/webacademyufam/hands-on-t4-03-glowmatch',
                developers: [
                    {
                        name: 'Beatriz Aguiar',
                        img_profile: 'assets/img/turma4/beatriz.jpeg',
                    },
                    {
                        name: 'Charlene Queiroz',
                        img_profile: 'assets/img/turma4/charlene.jpeg',
                    },
                    {
                        name: 'Cristian Garcia',
                        img_profile: 'assets/img/turma4/cristian.png',
                    },
                    {
                        name: 'Daniel Leal',
                        img_profile: 'assets/img/turma4/daniel.jpeg',
                    },
                    {
                        name: 'Fílip Anselmo',
                        img_profile: 'assets/img/turma4/filip.jpeg',
                    },
                    {
                        name: 'José Lázaro',
                        img_profile: 'assets/img/turma4/lazaro.jpeg',
                    },
                ]
            },
            {
                id: '16',
                title: 'DeskSense',
                description: 'Sistema de helpdesk que utiliza I.A. para analisar a descrição dos chamados, classificando automaticamente a prioridade com base no tom emocional do conteúdo textual.',
                categories: 'Social',
                logo: 'assets/img/handson/desksense.png',
                status: 'Finalizado',
                github: 'https://github.com/webacademyufam/hands-on-t4-04-desksense',
                developers: [
                    {
                        name: 'Adriano Nobre',
                        img_profile: 'assets/img/turma4/adriano.jpeg',
                    },
                    {
                        name: 'Eduardo Okita',
                        img_profile: 'assets/img/turma4/okita.png',
                    },
                    {
                        name: 'Kristine Santana',
                        img_profile: 'assets/img/turma4/kristine.jpeg',
                    },
                    {
                        name: 'Suelen Pereira',
                        img_profile: 'assets/img/turma4/suellen.jpeg',
                    },
                    {
                        name: 'Thiago Martins',
                        img_profile: 'assets/img/turma4/thiago.png',
                    }
                ]
            },

        ],
    },
    {
        id: '3',
        turma: 'Turma 3',
        projects: [
            {
                id: '9',
                title: 'Harmonic',
                description: 'Plataforma de streaming de música que permite aos usuários ouvir suas músicas favoritas de forma prática e personalizada.',
                categories: 'Musica, Entreitenimento',
                logo: 'assets/img/handson/harmonic.png',
                status: 'Finalizado',
                github: 'https://girlana.atlassian.net/jira/software/projects/HAR/boards/2/backlog?atlOrigin=eyJpIjoiODdjYmE0NDkwZDBhNGQ0OTljZTMwM2RmMThhMGYwNjIiLCJwIjoiaiJ9',
                developers: [
                    {
                        name: 'Natanael de Oliveira',
                        img_profile: 'assets/img/turma3/natanael.jpeg',
                    },
                    {
                        name: 'Saile Costa',
                        img_profile: 'assets/img/turma3/saile2.png',
                    },
                    {
                        name: 'Girlana Santos',
                        img_profile: 'assets/img/turma3/girlana.png',
                    },
                    {
                        name: 'Isabella Mendes',
                        img_profile: 'assets/img/turma3/isabella.png',
                    },
                ]
            },
            {
                id: '10',
                title: 'OptiHire',
                description: 'Plataforma para processo de gestão de candidatos. O RH pode gerenciar os candidatos, provas e os respectivos desempenhos. ',
                categories: 'gestão, empregabilidade',
                logo: 'assets/img/handson/optihire.png',
                status: 'Finalizado',
                github: 'https://github.com/users/webacademyufam/projects/8',
                developers: [
                    // {
                    //     name: 'Ademar Castro',
                    //     img_profile: 'https://ca.slack-edge.com/T052KR1029Y-U06GHAMAY1H-35fb2de34cb8-512',
                    // },
                    {
                        name: 'Davi Souza',
                        img_profile: 'assets/img/turma3/davi.jpeg',
                    },
                    {
                        name: 'Gabriel Freitas',
                        img_profile: 'assets/img/turma3/gabrielF.png',
                    },
                    {
                        name: 'Gabriel Pacheco',
                        img_profile: 'assets/img/turma3/gabrielP.png',
                    },
                    {
                        name: 'Vinicius Fonseca',
                        img_profile: 'assets/img/turma3/vinicius.png',
                    },
                ]
            },
            {
                id: '11',
                title: 'Tiquim',
                description: 'Plataforma de financiamento coletivo que permite aos usuários criar campanhas para arrecadar fundos para projetos ou causas.',
                categories: 'Social',
                logo: 'assets/img/handson/tiquim.png',
                status: 'Finalizado',
                github: 'https://github.com/users/webacademyufam/projects/7',
                developers: [
                    {
                        name: 'Allan Aguiar',
                        img_profile: 'assets/img/turma3/allan.png',
                    },
                    {
                        name: 'Ayrton Lemes',
                        img_profile: 'assets/img/turma3/ayrton.png',
                    },
                    {
                        name: 'Brenda Moura',
                        img_profile: 'assets/img/turma3/brenda.jpeg',
                    },
                    {
                        name: 'Cáio Gonzaga',
                        img_profile: 'assets/img/turma3/caio.png',
                    },
                ]
            },
            {
                id: '12',
                title: 'PatrimoniX',
                description: 'Sistema web que auxilia no gerenciamento de ativos que compõem a carteira de investidores iniciantes ou experientes.',
                categories: 'Social',
                logo: 'assets/img/handson/PatrimoniX.png',
                status: 'Finalizado',
                github: 'https://github.com/users/webacademyufam/projects/10',
                developers: [
                    {
                        name: 'Daniele Greice',
                        img_profile: 'assets/img/turma3/daniele.jpeg',
                    },
                    // {
                    //     name: 'Fabrício Guimarães',
                    //     img_profile: 'https://ca.slack-edge.com/T052KR1029Y-U06GEG067EH-23d5e65e7272-512',
                    // },
                    {
                        name: 'Max Souza',
                        img_profile: 'assets/img/turma3/max.jpeg',
                    },
                    {
                        name: 'Rodrigo Santos',
                        img_profile: 'assets/img/turma3/rodrigo.jpeg',
                    },
                    {
                        name: 'Thiago Oliveira',
                        img_profile: 'assets/img/turma3/tiago.png',
                    },
                ]
            },

        ],
    },
    {
        id: '1',
        turma: 'Turma 1',
        projects: [
            {
                id: '1',
                title: 'Pyramid - AACC System',
                description: 'Realizar o gerenciamento das atividades de ensino, pesquisa e extensão entre aluno e coordenação e secretaria de curso',
                categories: 'Educação, Gerenciamento',
                logo: 'assets/img/handson/aacc.jpg',
                status: 'Finalizado',
                github: 'https://github.com/tacia68/AACC',
                developers: [
                    {
                        name: "Shermam Lima",
                        img_profile: 'assets/img/turma1/shermam.png',
                    },
                    {
                        name: "Eduardo A. Araújo",
                        img_profile: 'assets/img/turma1/eduardo.png',
                    },
                    {
                        name: "Jeison P. Oliveira",
                        img_profile: 'assets/img/turma1/jeison.png',
                    },
                    {
                        name: "Josué Lustosa Filho",
                        img_profile: 'assets/img/turma1/josue.png',
                    },
                    {
                        name: "Bruno R. Rodrigues",
                        img_profile: 'assets/img/turma1/bruno.png',
                    },
                ]
            },
            {
                id: '2',
                title: 'Sistema Acadêmico - SACT',
                description: 'Otimizar a administração de turmas de capacitação, com ferramentas para facilitar a gestão e o monitoramento do desempenho acadêmico.',
                categories: 'Gerenciamento, Educação',
                logo: 'assets/img/handson/sact.jpg',
                status: 'Finalizado',
                github: 'https://github.com/amoedogabriel/SACT',
                developers: [
                    {
                        name: "José Alberto Filho",
                        img_profile: 'assets/img/turma1/jose.png',
                    },
                    {
                        name: "Paula Mendonça",
                        img_profile: 'assets/img/turma1/paula.png',
                    },
                    {
                        name: "Taynara Costa",
                        img_profile: 'assets/img/turma1/taynara.png',
                    },
                ]
            },
            {
                id: '3',
                title: 'GPD Planning Tool',
                description: 'Realizar a gestão de produtos de softwares, prazos de entrega, recursos humanos e colaboradores.',
                categories: 'Gerenciamento, Desenvolvimento',
                logo: 'assets/img/handson/gpd.jpg',
                status: 'Finalizado',
                github: '#',
                developers: [
                    {
                        name: "Gleides Silva",
                        img_profile: 'assets/img/turma1/gleides.png',
                    },
                    {
                        name: "Laura Lima Guedes",
                        img_profile: 'assets/img/turma1/laura.png',
                    },
                    {
                        name: "Nathália R. M. Santos",
                        img_profile: 'assets/img/turma1/nathalia.png',
                    },
                    {
                        name: "Solano Lima Oliveira",
                        img_profile: 'assets/img/turma1/solano.png',
                    },
                ]
            },
            {
                id: '4',
                title: 'Biker Xperience',
                description: 'Plataforma inovadora para reserva de aulas de bike, oferecendo uma experiência simplificada permitindo a organização de aulas, definição de horários, salas e bikes. ',
                categories: 'Administracao, Bike',
                logo: 'assets/img/handson/biker.jpg',
                status: 'Finalizado',
                github: 'https://github.com/Biker-Xperience',
                developers: [
                    {
                        name: "Gabriel B. Cerquinho",
                        img_profile: 'assets/img/turma1/gabriel.png',
                    },
                    {
                        name: "Gutemberg B. Ferreira",
                        img_profile: 'assets/img/turma1/gutemberg.jpeg',
                    },
                    {
                        name: "Jonathas B. Cavalcante",
                        img_profile: 'assets/img/turma1/jonathas.png',
                    },
                    {
                        name: "José Rodrigues",
                        img_profile: 'assets/img/turma1/mateus.png',
                    },
                    {
                        name: "Josias B. F. Cavalcante",
                        img_profile: 'assets/img/turma1/josias.png',
                    },
                ]
            },
        ],


    },

    {
        id: '2',
        turma: 'Turma 2',
        projects: [
            {
                id: '5',
                title: 'Learnify',
                description: 'Plataforma de Cursos On-line: gestão de alunos, material, avaliações, instrutores.',
                categories: 'Educação',
                logo: 'assets/img/handson/learnify.png',
                status: 'Finalizado',
                github: 'https://github.com/webacademyufam/hands-on-t2-02-learnify',
                developers: [
                    {
                        name: 'Daniel Antunes',
                        img_profile: 'https://ca.slack-edge.com/T052KR1029Y-U05MPF7SL6R-824484f7c933-512',
                    },
                    {
                        name: 'Eduardo Noji',
                        img_profile: 'https://ca.slack-edge.com/T052KR1029Y-U05MS9WJS67-62a81a5b510a-512',
                    },
                    {
                        name: 'Gabriel Amoêdo',
                        img_profile: 'https://ca.slack-edge.com/T052KR1029Y-U05346W0E2D-c7b106c98540-512',
                    },
                    {
                        name: 'Vitor Xavier',
                        img_profile: 'assets/img/turma2/vitor.jpg',
                    },
                    {
                        name: 'Vitória Maciel',
                        img_profile: 'https://avatars.githubusercontent.com/u/91225063?v=4',
                    },
                ]
            },
            {
                id: '6',
                title: 'TicketPlus',
                description: 'Plataforma de Venda de tickets para Eventos - gestão de eventos, cronogramas, venda de bilhetes, pagamentos, etc.',
                categories: 'e-commerce',
                logo: 'assets/img/handson/ticketplus.png',
                status: 'Finalizado',
                github: 'https://github.com/webacademyufam/hands-on-t2-04-ticketplus',
                developers: [
                    {
                        name: 'Aris Souza',
                        img_profile: 'https://ca.slack-edge.com/T052KR1029Y-U05MKP8S30E-26a8d4f035bb-512',
                    },
                    // {
                    //     name: 'Cleonice Garcia',
                    //     img_profile: 'https://ca.slack-edge.com/T052KR1029Y-U05MBS9AA4X-7b10b202ed55-512',
                    // },
                    {
                        name: 'Ícaro Santos',
                        img_profile: 'https://avatars.githubusercontent.com/u/46682140?v=4',
                    },
                    {
                        name: 'Luiz Gustavo',
                        img_profile: 'assets/img/turma2/luiz.jpg',
                    },
                    {
                        name: 'Noah Diunkz',
                        img_profile: 'https://avatars.githubusercontent.com/u/18603573?v=4',
                    },
                    {
                        name: 'Salomão Cruz',
                        img_profile: 'assets/img/turma2/salomao.jpg',
                    },
                ]
            },
            {
                id: '7',
                title: 'SmartNest',
                description: 'Um sistema em alto-nível para automação residencial - usuários criam cômodos, adicionam devices (controladores de eletros e equipamentos)  e fazem  gerenciamento via web',
                categories: 'Adminstracao, IoT',
                logo: 'assets/img/handson/smartnest.png',
                status: 'Finalizado',
                github: 'https://github.com/webacademyufam/hands-on-t2-03-smartnest',
                developers: [
                    {
                        name: 'Adriano Gomes',
                        img_profile: 'assets/img/turma2/adriano.jpeg',
                    },
                    {
                        name: 'Antônio Neto',
                        img_profile: 'https://ca.slack-edge.com/T052KR1029Y-U05MBSATXMM-872a35a66d02-512',
                    },
                    {
                        name: 'Caio Fernandes',
                        img_profile: 'https://ca.slack-edge.com/T052KR1029Y-U05NG3ZSDPS-4c5769619cee-512',
                    },
                    {
                        name: 'Caroline Braz',
                        img_profile: 'assets/img/turma2/carol.jpg',
                    },
                    // {
                    //     name: 'Isabella Mendes',
                    //     img_profile: 'https://ca.slack-edge.com/T052KR1029Y-U05N51YSJ9X-085f72a680e9-512',
                    // },
                ]
            },
            {
                id: '8',
                title: 'HobbySphere',
                description: 'Uma rede social focada em hobbies - usuários que compartilham os mesmos hobbies trocam informações sobre o seu gosto comum - vídeos, imagens, likes, comentários, etc.',
                categories: 'Social',
                logo: 'assets/img/handson/hobbysphere.png',
                status: 'Finalizado',
                github: 'https://github.com/webacademyufam/hands-on-t2-01-hobbysphere',
                developers: [
                    // {
                    //     name: 'Alexandre Barros',
                    //     img_profile: 'https://ca.slack-edge.com/T052KR1029Y-U05MBSA6P3R-g1481612fb54-512',
                    // },
                    {
                        name: 'Crislan Cruz',
                        img_profile: 'assets/img/turma2/crislan.jpg',
                    },
                    {
                        name: 'Eduardo Silva',
                        img_profile: 'assets/img/turma2/eduardo.jpg',
                    },
                    {
                        name: 'Francisco Gabriel',
                        img_profile: 'https://ca.slack-edge.com/T052KR1029Y-U05MSCQ5BH8-e0218ae3bca9-512',
                    },
                    {
                        name: 'Gabriel Sobrinho',
                        img_profile: 'https://ca.slack-edge.com/T052KR1029Y-U05N51YGTMX-75f78d6bb02d-512',
                    },
                    {
                        name: 'Sidney Araújo',
                        img_profile: 'https://ca.slack-edge.com/T052KR1029Y-U05MPFVHSE9-ff36e601d52e-512',
                    },
                ]
            },

        ],
    },



]

export default HandsonData;