import React, { useState, useEffect } from 'react';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import slideImages from './ImagensDB';
import './SlideShow.css'

const SlideShow = () => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 960);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 960);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const baseStyle = {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        backgroundSize: isMobile ? 'contain' : 'cover',
        height: isMobile ? '300px' : '455px',
        borderRadius: '10px',
        transition: 'height 0.3s, background-size 0.3s'
    };

    return (
        <div className="slide-container my-5">
            <Fade>
                {
                    slideImages.map((slideImage, index) => (
                        <div key={index}>
                            <div style={{ ...baseStyle, 'backgroundImage': `url(${slideImage.url})` }}>
                                <span className='spanStyle'>{slideImage.caption}</span>
                            </div>
                        </div>
                    ))
                }
            </Fade >
        </div >
    )

}

export default SlideShow;

