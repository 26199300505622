import React from 'react';
import Container from 'react-bootstrap/Container';
import SlideShow from '../../components/slideshow/SlideShow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faBrazilianRealSign, faUserTie } from '@fortawesome/free-solid-svg-icons';
import { faTelegram } from '@fortawesome/free-brands-svg-icons';




function Home() {
	return (

		<Container>
			{/* <div className="row g-3 justify-content-center align-items-center mb-5 ">
				<div className="col-md-7 col-sm-12">

					<SlideShow></SlideShow>

					<br />

					<h3 className="text-center">O Projeto</h3>

					<p className="text-center">
						O Web Academy é um projeto realizado pela Universidade Federal do Amazonas (UFAM), em
						parceria com a Motorola Mobility Comércio de Produtos Eletrônicos Ltda e Flextronics
						da Amazônia Ltda. O projeto visa a formação de profissionais na área de Desenvolvimento em Web Full
						Stack, com foco em alunos de graduação, pós-graduação e profissionais do mercado
						com curso superior.

					</p>
				</div>

				<div className="col-md-5">

					<div className="row g-3 justify-content-center mb-5">
						<img style={{ minWidth: "7rem", maxWidth: "7rem" }} src="assets/img/ufam_logo.png" className="img-fluid thumbnail" alt="logo ufam" />
						<img style={{ minWidth: "7rem", maxWidth: "9rem" }} src="assets/img/icomp.png" className="img-fluid thumbnail" alt="logo icomp" />
					</div>

					<div className="row g-3 justify-content-center">
						<img style={{ minWidth: "7rem", maxWidth: "7rem" }} src="assets/img/Motorola_logo.svg" className="img-fluid thumbnail" alt="logo motorola" />
						<img style={{ minWidth: "7rem", maxWidth: "10rem" }} id="flexlogo" src="assets/img/flex_logo.png" className="img-fluid thumbnail" alt="logo flextronics" />
					</div>
				</div>

			</div> */}

			<header>
				<div className="container px-5">
					<div className="row gx-5 align-items-center justify-content-center">
						<div className="col-lg-5 col-xl-5 col-xxl-6" style={{ width: '80%' }}>
							<div className="my-5 text-center text-xl-start">
								<h1 className="display-5 fw-bolder  mb-2" style={{ color: '#3E79BC', fontFamily: 'Montserrat Alternates, sans-serif', fontSize: '3.5em', textAlign: 'center' }}><img src='assets/img/old2_logo_novo_site_icon.png' style={{ width: '80px' }} alt='logo site webacademy' /> web academy</h1>
								<p className="lead fw-normal text-black-50 mb-4" style={{ marginTop: '50px' }}> O projeto visa a formação de profissionais na área de Desenvolvimento em Web Full
									Stack com foco em estudantes de graduação, pós-graduação e profissionais do mercado
									com curso superior.</p>
								<div className="d-grid gap-3 d-sm-flex justify-content-sm-center ceter-content-xl-start">
									<a className="btn btn-primary btn-lg px-4 me-sm-3" href="/curso" style={{ backgroundColor: '#3E79BC' }}>Saiba mais</a>
									{/* <a class="btn btn-primary btn-lg px-4" href="#!">Learn More</a> */}
								</div>
							</div>
						</div>

					</div>

					<div className="row gx-5 align-items-center justify-content-center">
						<div style={{ width: "100%" }}>

							<SlideShow></SlideShow>
							{/* <img className="img-fluid rounded-3 my-5" src="https://dummyimage.com/600x400/343a40/6c757d" alt="..." /> */}
						</div>
					</div>
				</div>
			</header>


			<section className="py-5" id="features">
				<div className="container px-5 my-5">
					<div className="row gx-5">
						<div className="col-lg-4 mb-5 mb-lg-0" style={{ color: '#3E79BC' }}>
							<h2 className="fw-bolder mb-0">O melhor jeito de iniciar no Desenvolvimento Web.</h2>
						</div>
						<div className="col-lg-8">
							<div className="row gx-5 row-cols-1 row-cols-md-2">
								<div className="col mb-5 h-100">
									<div className="feature  bg-gradient text-white rounded-3 mb-3" style={{ width: '20%', padding: '7px', textAlign: 'center', fontSize: '1.5em', backgroundColor: '#3E79BC' }}><FontAwesomeIcon icon={faBuilding} style={{ fontSize: '1.5em' }} /></div>
									<h2 className="h5" style={{ color: '#3E79BC' }}>Estrutura</h2>
									<p className="mb-0 text-black-50"> Laboratórios de Prática de Programação equipados com computadores de alto desempenho.</p>
								</div>
								<div className="col mb-5 h-100">
									<div className="feature bg-gradient text-white rounded-3 mb-3" style={{ width: '20%', padding: '7px', textAlign: 'center', fontSize: '1.5em', backgroundColor: '#3E79BC' }}><FontAwesomeIcon icon={faUserTie} style={{ fontSize: '1.5em' }} /></div>
									<h2 className="h5" style={{ color: '#3E79BC' }} >Grupo Docente</h2>
									<p className="mb-0 text-black-50">Professores(as) com anos de experiência acadêmica e na indústria da tecnologia.</p>
								</div>
								<div className="col mb-5 mb-md-0 h-100">
									<div className="feature  bg-gradient text-white rounded-3 mb-3" style={{ width: '20%', padding: '7px', textAlign: 'center', fontSize: '1.5em', backgroundColor: '#3E79BC' }}><FontAwesomeIcon icon={faTelegram} style={{ fontSize: '1.5em' }} /></div>
									<h2 className="h5" style={{ color: '#3E79BC' }} >Suporte de Monitoria</h2>
									<p className="mb-0 text-black-50">Suporte presencial e online de desenvolvedores FullStack para acompanhamento e avaliação. </p>
								</div>
								<div className="col h-100">
									<div className="feature bg-gradient text-white rounded-3 mb-3" style={{ width: '20%', padding: '7px', textAlign: 'center', fontSize: '1.5em', backgroundColor: '#3E79BC' }}><FontAwesomeIcon icon={faBrazilianRealSign} style={{ fontSize: '1.5em' }} /></div>
									<h2 className="h5" style={{ color: '#3E79BC' }} >Auxílio-Capacitação</h2>
									<p className="mb-0 text-black-50">Incentivo financeiro para auxiliar na permanência do curso </p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>


			<section className="py-5 ">
				<div className="container px-5 my-5">
					{/* <div class="text-center">
						<h2 class="fw-bolder">Our team</h2>
						<p class="lead fw-normal text-muted mb-5">Dedicated to quality and your success</p>
					</div> */}
					<div className="row gx-5 row-cols-1 row-cols-sm-2 row-cols-xl-4 justify-content-center">
						<div className="col mb-5 mb-5 mb-xl-0">
							<div className="text-center">
								{/* <img className="img-fluid rounded-circle mb-4 px-4" src="https://dummyimage.com/150x150/ced4da/6c757d" alt="..." /> */}
								<img style={{ maxWidth: "7rem" }} src="assets/img/ufam_logo.png" className="img-fluid thumbnail" alt="logo ufam" />
								{/* <h5 class="fw-bolder">Ibbie Eckart</h5> */}
								{/* <div class="fst-italic text-muted">Founder &amp; CEO</div> */}
							</div>
						</div>
						<div className="col mb-5 mb-5 mb-xl-0">
							<div className="text-center">
								{/* <img className="img-fluid rounded-circle mb-4 px-4" src="https://dummyimage.com/150x150/ced4da/6c757d" alt="..." /> */}
								<img src="assets/img/icomp.png" className="img-fluid thumbnail" alt="logo icomp" />
								{/* <h5 class="fw-bolder">Arden Vasek</h5> */}
								{/* <div class="fst-italic text-muted">CFO</div> */}
							</div>
						</div>
						<div className="col mb-5 mb-5 mb-sm-0">
							<div className="text-center">
								{/* <img className="img-fluid rounded-circle mb-4 px-4" src="https://dummyimage.com/150x150/ced4da/6c757d" alt="..." /> */}
								<img style={{ maxWidth: "12rem" }} src="assets/img/Motorola_logo.svg" className="img-fluid thumbnail" alt="logo motorola" />
								{/* <h5 class="fw-bolder">Toribio Nerthus</h5> */}
								{/* <div class="fst-italic text-muted">Operations Manager</div> */}
							</div>
						</div>
						<div className="col mb-5">
							<div className="text-center">
								{/* <img className="img-fluid rounded-circle mb-4 px-4" src="https://dummyimage.com/150x150/ced4da/6c757d" alt="..." /> */}
								<img style={{ maxWidth: "20rem" }} src="assets/img/flex_logo.png" className="img-fluid thumbnail" alt="logo flextronics" />
								{/* <h5 class="fw-bolder">Malvina Cilla</h5> */}
								{/* <div class="fst-italic text-muted">CTO</div> */}
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* <div className="row g-3 justify-content-center align-items-center">
				<div className="order-1 col-md-5 col-sm-12 order-sm-0">

					<div className="row g-3 justify-content-center mb-5">
						<img style={{ minWidth: "10rem", maxWidth: "12rem" }} src="assets/img/web.png" className="img-fluid" alt="computador com ferramentas web" />
					</div>

				</div>

				<div className="order-0 col-md-7 col-sm-12 order-sm-1">

					<h3 className="text-center">Curso</h3>

					<p className="text-center">
						O objetivo do curso é formar profissionais capacitados em desenvolviemnto web full stack, cobrindo todas as etapas
						do desenvolvimento de uma aplicação de software, com o uso de metodologias e ferramentas modernas.

					</p>
				</div>
			</div> */}

		</Container >
	)
}

export default Home