import React, { useState } from "react";
import Container from "react-bootstrap/Container"
import ToggleButton from "../../components/editais/ToggleButton";
import ToggleContent from "../../components/editais/ToggleContent";

function ProcessoSeletivo() {

	const [isVisible, setIsVisible] = useState(false);

	const toggleContent = () => {
		setIsVisible(!isVisible);
	}

	return (
		<Container className="mt-5">
			<div className="row g-3 justify-content-center  mb-5">
				<div className="col-md-8 mt-3">
					{/* <h2>Inscrições</h2> */}

					<section className="py-5">
						<div className="container px-5">
							<div className="row gx-5">
								<div className="col">
									{/* <div className="mb-4">
										<h2> Em breve inscrições para Turma 5!!!</h2>
										<img src="assets/img/ampulheta.gif" alt="https://icons8.com/icon/TmIKpxTOSBi7/hourglass" />
									</div> */}

									<section className="py-4" style={{ backgroundColor: '#ecf0f1', padding: '20px', marginBottom: '50px' }}>
										<h2 className="mb-4"> <strong> Edital 002/2025 - 1ª Turma EaD </strong></h2>

										<div className="mb-4">
											<p> Este edital destina-se à formação da 1ª turma EaD do Projeto Web Academy para residentes em <strong>Benjamin Constant</strong>, <strong>Tabatinga</strong> e <strong>Atalaia do Norte</strong>, no Amazonas.</p>
										</div>

										<div className="mb-4">
											<div className="maedium text-muted">10 de Março, 2025</div>
											<a target="_blank" rel="noopener noreferrer" href="files/edital_002_2025_ead/Projeto_Web_Academy_Edital_002_2025_Selecao_de_Discentes_Turma_1_EaD.pdf"><h4>Edital 002/2025 - Seleção de Discentes</h4></a>
										</div>

										<div className="mb-4">
											<div className="maedium text-muted">14 a 30 de Março, 2025</div>
											<a target="_blank" rel="noopener noreferrer" href="https://forms.gle/oxwZ1LFc59Bd98bp6">
												<h4>Formulário para Requerimento de Inscrição</h4>
											</a>
										</div>

									</section>

									<h2 className="mb-4"> <strong> Edital 001/2025 - Turma 5 </strong></h2>

									<div className="mb-4">
										<div className="maedium text-muted">19 de Fevereiro, 2025</div>
										<a target="_blank" rel="noopener noreferrer" href="files/edital_001_2025/Projeto_Web_Academy_Edital_001_2025 _Seleção de Discentes_Turma_5.pdf"><h4>Edital 001/2025 - Seleção de Discentes</h4></a>
									</div>

									<div className="mb-4">
										<div className="maedium text-muted">19 Fevereiro a 02 de Março, 2025</div>
										{/* <a target="_blank" rel="noopener noreferrer" href="https://forms.gle/z8o9YEQUJYDoupot9"> */}
										<h4>Formulário para Requerimento de Inscrição (encerrado)</h4>
										{/* </a> */}
									</div>

									<div className="mb-4">
										<div className="maedium text-muted">07 de Março, 2025</div>
										<a target="_blank" rel="noopener noreferrer" href="files/edital_001_2025/Projeto_Web_Academy_Edital_ 001_2025_Resultado_Preliminar_da_Primeira_Fase.pdf">
											<h4>Resultado Preliminar da Primeira Fase</h4>
										</a>
									</div>

									<div className="mb-4">
										<div className="maedium text-muted">10 de Março, 2025</div>
										<a target="_blank" rel="noopener noreferrer" href="files/edital_001_2025/Projeto_Web_Academy_Edital_001_2025_Resultado_Final_da_Primeira_Fase.pdf">
											<h4>Resultado Final da Primeira Fase e Convocação para Etapa 2</h4>
										</a>
									</div>

									<div className="mb-4">
										<div className="maedium text-muted">18 de Março, 2025</div>
										<a target="_blank" rel="noopener noreferrer" href="files/edital_001_2025/Projeto_Web_Academy_Edital_001_2025_Resultado_Final_Preliminar.pdf">
											<h4>Resultado Final Preliminar</h4>
										</a>
									</div>

									<div className="mb-4">
										<div className="maedium text-muted">21 de Março, 2025</div>
										<a target="_blank" rel="noopener noreferrer" href="files/edital_001_2025/Projeto_Web_Academy_Edital_001_2025_Resultado_Final.pdf">
											<h4>Resultado Final</h4>
										</a>
									</div>






									{/* <div className="mb-4">
										<div className="small text-muted">08 de Julho, 2024</div>
										<a target="_blank" rel="noopener noreferrer" href="files/edital_002_2024/Projeto Web Academy - Edital 002_2024 - Resultado Preliminar da Primeira Fase.pdf">
											<h4>Resultado Preliminar da Primeira Fase</h4>
										</a>
									</div>

									

									<div className="mb-4">
										<div className="small text-muted">09 de Julho, 2024</div>
										<a target="_blank" rel="noopener noreferrer" href="files/edital_002_2024/Projeto Web Academy - Edital 002_2024 - Convocacao para Etapa 2.pdf">
											<h4>Convocação para Etapa 2</h4>
										</a>
									</div>

									<div className="mb-4">
										<div className="small text-muted">17 de Julho, 2024</div>
										<a target="_blank" rel="noopener noreferrer" href="files/edital_002_2024/Projeto Web Academy - Edital 002_2024 - Selecao de Discentes - ERRATA01.pdf">
											<h4>ERRATA 01 - Edital 002/2024 </h4>
										</a>
									</div>

									<div className="mb-4">
										<div className="small text-muted">18 de Julho, 2024</div>
										<a target="_blank" rel="noopener noreferrer" href="files/edital_002_2024/Projeto_Web_Academy_Edital 002_2024_Resultado_Final_Preliminar.pdf">
											<h4>Resultado Final Preliminar</h4>
										</a>
									</div>

									<div className="mb-4">
										<div className="small text-muted">22 de Julho, 2024</div>
										<a target="_blank" rel="noopener noreferrer" href="files/edital_002_2024/Projeto_Web_Academy_Edital_002_2024_Resultado_Final.pdf">
											<h4>Resultado Final</h4>
										</a>
									</div> */}


									{/* <div className="mb-4">
										<div className="small text-muted">May 5, 2023</div>
										<a className="link-dark" href="#!"><h4>Bootstrap 5 has officially landed</h4></a>
									</div>

									<div className="mb-4">
										<div className="small text-muted">Apr 21, 2023</div>
										<a className="link-dark" href="#!"><h4>This is another news article headline, but this one is a little bit longer</h4></a>
									</div> */}
									<ToggleButton isVisible={isVisible} toggleContent={toggleContent} />
								</div>
							</div>
						</div>
					</section>


					<ToggleContent isVisible={isVisible} />

					{/* <p> Agosto de 2024 </p> */}

					{/* <p style={{ fontSize: '20px' }}>O processo seletivo para admissão de alunos para a <b>Turma 3</b> é regido pelo edital disponível abaixo:</p>
					<ul>
						<li style={{ fontSize: '20px', marginBottom: '15px' }}>
							<a target="_blank" rel="noopener noreferrer" href="files/edital_001_2024.pdf" >Edital 001/2024 - Seleção de Discentes</a>
						</li>

						<p style={{ fontSize: '20px' }}>O período de inscrição para o processo seletivo será de <strong>02/01/2024</strong> a <strong>12/01/2024</strong>.</p>

						<li style={{ fontSize: '20px', marginBottom: '15px' }}>
							<a target="_blank" rel="noopener noreferrer" href="files/homologacao_inscricoes_edital_001_2024.pdf" >Resultado da Homologação das Inscrições</a>
						</li>
						<li style={{ fontSize: '20px', marginBottom: '15px' }}>
							<a target="_blank" rel="noopener noreferrer" href="files/Edital 001_2024_Resultado_Final_Preliminar.pdf" >Resultado Final Preliminar da Homologação das Inscrições</a>
						</li>
						<li style={{ fontSize: '20px' }}>
							<a target="_blank" rel="noopener noreferrer" href="files/Edital_001_2024_-_Resultado_Final.pdf" >Resultado Final</a>
						</li> */}
					{/* <li>
							<a target="_blank" rel="noopener noreferrer" href="files/errata_edital_002_2023.pdf">Errata - Edital 002/2023</a>
						</li>
						<li>
							<a target="_blank" rel="noopener noreferrer" href="files/errata2_edital_002_2023.pdf" >Errata 2 - Edital 002/2023</a>
						</li>
						<li>
							<a target="_blank" rel="noopener noreferrer" href="files/homologacao_inscricoes_edital_002_2023.pdf" >Resultado da Homologação das Inscrições</a>
						</li>
						<li>
							<a target="_blank" rel="noopener noreferrer" href="files/errata_homologacao_inscricoes_edital_002_2023.pdf" >Errata - Resultado da Homologação das Inscrições</a>
						</li> */}


					{/* </ul> */}


					{/* <p style={{ fontSize:'20px' }}>As inscrições serão efetuadas exclusivamente de forma on-line, através do <a target="_blank" rel="noopener noreferrer" href="https://forms.gle/dnehp72u7TnY9zDG8"> Requerimento
						de Inscrição</a>.</p> */}


					{/* <hr className="m-5" /> */}
				</div>
			</div >

			{/* <div className="row g-3 justify-content-center">
				<div className="col-md-8">
					<h3>Resultado</h3>
					<ul>
						<li>
							<a target="_blank" rel="noopener noreferrer" href="files/resultado_edital_002_2023.pdf" >Resultado Preliminar - Edital 002/2023</a>
						</li>
						<li>
							<a target="_blank" rel="noopener noreferrer" href="files/resultado_final_edital_002_2023.pdf" >Resultado Final - Edital 002/2023</a>
						</li>	
					</ul>

				</div>
			</div> */}
		</Container >
	)
}

export default ProcessoSeletivo