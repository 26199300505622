import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const ToggleButton = ({isVisible, toggleContent}) => {
  return (
    <button className="text-end mb-5 mb-xl-0 btn btn-primary" onClick={toggleContent} style={{ backgroundColor: '#3E79BC' }}>
        Editais encerrados&nbsp;
        {isVisible ? (
            <>
                <FontAwesomeIcon icon={faEye} />
            </>
        ) : (

            <>
                <FontAwesomeIcon icon={faEyeSlash} />
            </>
        )
        }
    </button>
  );
};

export default ToggleButton;
